// extracted by mini-css-extract-plugin
export var aArrow1 = "sd";
export var aArrow2 = "td";
export var aEmpty = "id";
export var aPrice = "ld";
export var aTagAnnounce = "pd";
export var aTagClose = "od";
export var aTagEntry = "md";
export var aTagFree = "rd";
export var aTagLottery = "qd";
export var aTagPayment = "nd";
export var aText = "ud";
export var aTitle = "jd";
export var animationMarquee = "yd";
export var pDetail = "xd";
export var pItemBatch = "vd";
export var pItemBatchAbs = "wd";
export var pItemList = "kd";