// extracted by mini-css-extract-plugin
export var aArrow1 = "rb";
export var aArrow2 = "sb";
export var aButton1 = "bb";
export var aButton2 = "cb";
export var aButton3 = "db";
export var aButton4 = "eb";
export var aButton5 = "fb";
export var aButtonHeaderLogin = "gb";
export var aButtonHeaderRegister = "hb";
export var aButtonHeaderSpLogin = "kb";
export var aButtonLogin = "jb";
export var aButtonRegister = "ib";
export var aEmpty = "Xa";
export var aInner = "_a";
export var aMarquee = "ab";
export var aTagAnnounce = "ob";
export var aTagClose = "nb";
export var aTagEntry = "lb";
export var aTagFree = "qb";
export var aTagLottery = "pb";
export var aTagPayment = "mb";
export var aText = "tb";
export var aTitle = "Ya";
export var animationMaquee = "ub";
export var animationMarquee = "vb";
export var pMarqueePageTitle = "Za";